.App {
  text-align: center;
  background-color: #121212;
  color: #00FF7F;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 8s linear;
  }
}

li {
  list-style-type: none;
  padding: 10px;
  margin: 5px 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lista {
  margin-top: 30px;
  padding: 20px;
  background-color: #e8eaf6;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.timestamp {
  font-family: 'Courier New', monospace;
  font-size: small;
  color: #555;
}

body {
  background-color: #121212;
  color: #fff;
  margin: 0;
}

#root {
  background-color: #121212;
  min-height: 100vh;
  max-width: 100%; /* Prevent horizontal scrollbars */
  overflow-x: hidden; /* Hide horizontal overflow */
  padding: 10px; /* Add padding for smaller screens */
}

.App-header {
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 2vmin); /* Adjust font size for smaller screens */
  color: #00FF7F;
  padding: 20px;
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.App-link {
  color: #00FF7F;
  text-decoration: none;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.flip-card {
  background-color: transparent;
  width: 200px;
  height: 150px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-card-front {
  background-color: #f4f4f9;
  color: #333;
}

.flip-card-back {
  background-color: #3f51b5;
  color: white;
  transform: rotateY(180deg);
}

button {
  background-color: #121212;
  color: #00FF7F;
  border: 1px solid #121212;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #00FF7F;
  color: #121212;
}
